function toggleContent() {

    let mm = gsap.matchMedia();
    mm.add({
    isMobile: "(max-width: 768px)",
    isDesktop: "(min-width: 769px)"
    }, (context) => {
    let { isMobile, isDesktop } = context.conditions;

    const btn = document.querySelectorAll('.faq__header svg'),
      container = document.querySelectorAll('.faq__description');

    btn.forEach((item, i) => {
      let toggleBlock = gsap.to(container[i], {
        height: "auto",
        paused: true,
        autoAlpha: 1,
        duration: isDesktop ? .3 : 0.7,
        ease: "power1.out",
      });

      btn[i].addEventListener("click", () => {
        btn[i].classList.toggle("open");
        container[i].classList.toggle("show");
        container[i].classList.contains("show") ? toggleBlock.play() : toggleBlock.reverse();
      });
    });

})
}
module.exports = toggleContent;