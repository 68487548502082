"use strict";

import send from "./modules/send";
import toggleContent from "./modules/toggleContent";
window.addEventListener("load", () => {
  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);
  SmoothScroll({
    // Время скролла 400 = 0.4 секунды
    animationTime: 700,
    // Размер шага в пикселях
    stepSize: 0,

    // Дополнительные настройки:

    // Ускорение
    accelerationDelta: 20,
    // Максимальное ускорение
    accelerationMax: 1,

    // Поддержка клавиатуры
    keyboardSupport: true,
    // Шаг скролла стрелками на клавиатуре в пикселях
    arrowScroll: 50,

    // Pulse (less tweakable)
    // ratio of "tail" to "acceleration"
    pulseAlgorithm: true,
    pulseScale: 4,
    pulseNormalize: 1,

    // Поддержка тачпада
    touchpadSupport: true,
  });

  const langWrapper = document.querySelector('.header__lang');
  const en = document.querySelector('.header__button--en a');
  const ar = document.querySelector('.header__button--ar a');
  const systemLang = document.querySelector('html')
  const language = localStorage.getItem('lang');
  console.log(language)
  if (language === 'en') {
    systemLang.setAttribute('lang', 'en');
    langWrapper.classList.add('en');
    if(window.location.href !== window.location.origin + '/') {
      window.location.href = window.location.origin + '/'
    }
  } else if(language === 'ar') {
    localStorage.setItem('lang', 'ar');
    langWrapper.classList.remove('en');
    if(window.location.href !== window.location.origin + '/ar/') {
      window.location.href = window.location.origin + '/ar/'
    }
   } else if (language === null){
    localStorage.setItem('lang', 'en');
    langWrapper.classList.add('en');
    if(window.location.href !== window.location.origin +' /') {
      window.location.href = window.location.origin + '/'
    }

  }
   
  en.addEventListener('click', () => {
    localStorage.setItem('lang', 'en');
    langWrapper.classList.add('en');
  })

  ar.addEventListener('click', () => {
    localStorage.setItem('lang', 'ar');
    langWrapper.classList.remove('en');
  })



  send();
  toggleContent();
  
});

