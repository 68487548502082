const send = () => {
    
        let form = document.querySelector('.form');
        // const formWrapper = document.querySelector('.contact__inner');
        const serverResponce = document.querySelector('.contact__response');
        const closeBtn = document.querySelector('.contact__close');
        const send = document.querySelector('.form__btn');


        form.addEventListener("submit", async function (event) {
            event.preventDefault();
            let response = await fetch("/mailer/mail.php", {
                method: "POST",
                body: new FormData(form),
            });

            if (response.ok) {
                console.log('jo')
                serverResponce.classList.add('visible');
                form.classList.add('unvisivle')
                
            } else {
                console.log('fail')
                serverResponce.classList.add('visible');
                form.classList.add('unvisible')
               
            }
            form.reset();
        });


        closeBtn.addEventListener("click", (e) => {
            // if (formId === "#feedback") {
            //     document.querySelector('.feedback').classList.remove('visible')
            //     document.querySelector('html').classList.remove('lock')
            //     formWrapper.classList.remove('visible');
            //     serverResponce.classList.remove("visible");
            //     send.setAttribute('disabled', '');
            // } else {
            form.classList.remove('unvisible');
            serverResponce.classList.remove("visible");
            send.setAttribute('disabled', '');
            // closeMenu.click()
        });

}

module.exports = send;